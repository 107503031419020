@tailwind base;

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom utilities */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */
@layer base {
  :root {
    --radius: 0.5rem
  }
}
